import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showName, setShowName] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/') {
        setShowName(window.scrollY > 100);
      } else {
        setShowName(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial state

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  const menuItems = ['Home', 'Projects', 'Certificate', 'About', 'Contact'];

  const menuVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const menuItemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return (
    <Nav>
      <NavContent>
        <NameContainer
          initial={false}
          animate={{ opacity: showName ? 1 : 0, x: showName ? 0 : -50 }}
          transition={{ duration: 0.3 }}
        >
          <Name>Shinoj E M</Name>
        </NameContainer>
        <DesktopMenu>
          {menuItems.map((item) => (
            <MenuItem key={item} to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}>
              {item}
            </MenuItem>
          ))}
        </DesktopMenu>
        <MobileMenuToggle onClick={() => setIsOpen(!isOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </MobileMenuToggle>
      </NavContent>
      <AnimatePresence>
        {isOpen && (
          <MobileMenu
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            {menuItems.map((item) => (
              <MobileMenuItem
                key={item}
                variants={menuItemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to={item === 'Home' ? '/' : `/${item.toLowerCase()}`} onClick={() => setIsOpen(false)}>
                  {item}
                </Link>
              </MobileMenuItem>
            ))}
          </MobileMenu>
        )}
      </AnimatePresence>
    </Nav>
  );
};

const Nav = styled.nav`
  background-color: #121212;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const NameContainer = styled(motion.div)`
  overflow: hidden;
`;

const Name = styled.h1`
  color: #BB86FC;
  margin: 0;
  font-size: 1.5rem;
`;

const DesktopMenu = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;

  &:hover {
    color: #03DAC6;
  }
`;

const MobileMenuToggle = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    width: 25px;
    background: #ffffff;
    margin-bottom: 4px;
    transition: all 0.3s;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileMenu = styled(motion.ul)`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #121212;
  opacity: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
`;

const MobileMenuItem = styled(motion.li)`
  margin: 0.5rem 0;

  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;

    &:hover {
      color: #03DAC6;
    }
  }
`;

export default Navbar;
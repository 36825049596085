import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CertificateSection = () => {
  const [certificates, setCertificates] = useState([
    // Example certificates, you can start with an empty array if you prefer
    {
      id: 1,
      image: './img/certificates/backend-development.png',
      verifyLink: 'https://www.coursera.org/account/accomplishments/verify/U6NS8LS5SBRA',
      title: 'Introduction to Backend Development'
    },
    {
      id: 2,
      image: './img/certificates/python.png',
      verifyLink: 'https://www.coursera.org/account/accomplishments/verify/747T7LGZFUYX',
      title: 'Programming in Python'
    },
    {
        id: 3,
        image: './img/certificates/django.png',
        verifyLink: 'https://www.coursera.org/account/accomplishments/verify/L5X7MSH8YHST',
        title: 'Django Web Framework'
    },

  ]);

  const addCertificate = (newCertificate) => {
    setCertificates([...certificates, { ...newCertificate, id: Date.now() }]);
  };

 
  return (
    <SectionContainer>
      <SectionTitle>My Certificates</SectionTitle>
      <CertificateGrid>
        {certificates.map(cert => (
          <CertificateItem key={cert.id}>
            <CertificateImage
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <img src={cert.image} alt={cert.title} />
              <VerifyOverlay
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <VerifyLink href={cert.verifyLink} target="_blank" rel="noopener noreferrer">
                  Verify Certificate
                </VerifyLink>
              </VerifyOverlay>
            </CertificateImage>
            <CertificateTitle>{cert.title}</CertificateTitle>
          </CertificateItem>
        ))}
      </CertificateGrid>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  padding-top: 4rem;
  padding-left:1rem;
  padding-right:1rem;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  
`;

const SectionTitle = styled.h2`
  color: #BB86FC;
  text-align: center;
  text-size:1rem;
  margin-bottom: 2rem;
`;

const CertificateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CertificateItem = styled.div`
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const CertificateImage = styled(motion.div)`
  position: relative;
  overflow: hidden;
  flex: 1;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

const VerifyOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyLink = styled.a`
  color: #03DAC6;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 2px solid #03DAC6;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(3, 218, 198, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }
`;

const CertificateTitle = styled.h3`
  color: #ffffff;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export default CertificateSection;
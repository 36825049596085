import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <DesignedBy><Copyright>© {currentYear} Shinoj E M. All rights reserved.</Copyright>Designed and developed entirely by Shinoj E M</DesignedBy>
      </FooterContent>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #131313;
  color: #ffffff;
  padding:0px;
  margin-top: 0px; // This pushes the footer to the bottom if you're using a flex layout for the body
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Copyright = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #313540;
`;

const DesignedBy = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #313540;
`;

export default Footer;